/* LEFT
**************************************************/
.wrapper-left {
  position: relative;
  width: auto;
  height: auto;
  padding-left: 16px;
  max-width: 80%;
}

.content-left {
  background: white;
  padding: 8px 8px 8px 8px;
  border-radius: 8px;
}

.content-left-arrow {
  background: white;
  padding: 8px 8px 8px 8px;
  border-radius: 8px;
}

.content-left-arrow::after {
  content: '';
	position: absolute;
  left: 0;
  top: 0;
	border: 24px solid transparent;
	border-right-color: white;
	border-left: 0;
	border-top: 0;
}

/* RIGHT
**************************************************/
.wrapper-right{
  position: relative;
  width: auto;
  height: auto;
  padding-right: 16px;
}

.content-right {
  background: #dcf8c6;
  padding: 8px 8px 8px 8px;
  border-radius: 8px;
}

.content-right-arrow {
  background: #dcf8c6;
  padding: 8px 8px 8px 8px;
  border-radius: 8px;
}

.content-right-arrow::after {
  content: '';
	position: absolute;
	right: 0;
	top: 0;
	width: 0;
	height: 0;
	border: 24px solid transparent;
	border-left-color: #dcf8c6;
	border-right: 0;
	border-top: 0;
}