/** XS
*********************************************/
@media (max-width: 599px) {
  .brand-container {
    width: 75% !important;
    margin-left: 50% !important;
    transform: translate(-50%) !important;
    align-items: center !important;
  }

  .moon {
    width: 15% !important;
  }

  .ufo {
    width: 25% !important;
    margin-left: 70% !important;
  }

  .astronaut-container {
    width: 20% !important;
    height: auto;
    margin-left: 5%;
  }
  
  .astronaut-text {
    width: 25% !important;
  }

  .spacecraft {
    width: 25% !important;
    margin-left: 25% !important;
  }

  .dont-panic {
    font-size: 56pt !important;
  }

  .starman {
    width: 30% !important;
    margin-left: 65% !important;
  }

  .cloud-text {
    display: block;
    width: 35% !important;
    margin-left: 30%;
    opacity: 1
  }
}

/** SM
*********************************************/
@media (min-width: 600px) and (max-width: 959px) {
  .brand-container {
    width: 60% !important;
  }

  .moon {
    width: 15% !important;
  }

  .ufo {
    width: 20% !important;
    margin-left: 75% !important;
  }

  .astronaut-container {
    width: 15% !important;
    height: auto;
    margin-left: 5%;
  }
  
  .astronaut-text {
    width: 20% !important;
  }

  .spacecraft {
    width: 20% !important;
    margin-left: 20% !important;
  }

  .starman {
    width: 30% !important;
    margin-left: 65% !important;
  }

  .cloud-text {
    display: block;
    width: 35% !important;
    margin-left: 30%;
    opacity: 1
  }
}

/** MD
*********************************************/
@media (min-width: 960px) and (max-width: 1279px) {
}

/** LG
*********************************************/
@media (min-width: 1280px) and (max-width: 1919px) {
}

/** XL
*********************************************/
@media (min-width: 1920px) {
}

/** GT-XS
*********************************************/
@media (min-width: 600px) {
}

/** GT-SM
*********************************************/
@media (min-width: 960px) {
}

/** GT-MD
*********************************************/
@media (min-width: 1280px) {
}

/** GT-LG
*********************************************/
@media (min-width: 1920px) {
}