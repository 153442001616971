.contact {
  display: flex;
  flex-direction: row;
  height: 100vh;
}

.contact .left {
  flex: 1;
  display: flex;
  flex-direction: column;
  height: 100vh;
  justify-content: center;
  padding-left: 64px;
}

.contact .page-title {
  margin-bottom: 48px;
}

.contact .title {
  font-family: Roboto-Thin;
  font-weight: 100;
  font-size: 32px;
  color: white;
}

.contact .right {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
}

.phone-container {
  position: relative;
  background: url('../../assets/bg_whatsapp.png');
  background-size: cover;
}

.phone-placeholder {
  position: absolute;
  width: auto;
  height: 80%;
  visibility: hidden;
}

.safe-area {
  position: absolute;
  width: 90%;
  height: 90%;
  margin-top: 4%;
}

.safe-area input {
  height: 40px;
  border-radius: 24px;
  padding-left: 8px;
  padding-right: 8px;
  font-size: 14px;
  background-color: white;
  outline: none;
  border: none;
  user-select: none;
}

.sendButton {
  width: 40px;
  height: 40px;
  border-radius: 40px;
  margin-left: 8px;
  cursor: pointer;
  user-select: none;
}

.sendButton img {
  margin-left: 4px;
  width: 20px;
  height: 20px;
  user-select: none;
}

.sendButton:active {
  background-color: #005050;
}

.message {
  transition: opacity 1s ease-in-out;
}

.contact .whatsapp-xs {
  background: url('../../assets/bg_whatsapp.png');
  background-size: 100%;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  align-items: center;
  -webkit-tap-highlight-color: transparent;
  text-decoration: none;
  color: black;
}

.contact .whatsapp-xs .safe-area {
  position: relative;
  width: 90%;
  height: 100%;
  margin-top: 12%;
  padding-bottom: 24px;
}