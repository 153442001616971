/** PORTRAIT
*********************************************/
@media screen and (orientation:portrait) {

  .hide-pt { display: none !important; }

  /** XS
  *********************************************/
  @media (max-width: 599px) {
    .hide-pt-xs { display: none !important; }
  }

  /** SM
  *********************************************/
  @media (min-width: 600px) and (max-width: 959px) {
    .hide-pt-sm { display: none !important; }
  }

  /** MD
  *********************************************/
  @media (min-width: 960px) and (max-width: 1279px) {
    .hide-pt-md { display: none !important; }
  }

  /** LG
  *********************************************/
  @media (min-width: 1280px) and (max-width: 1919px) {
    .hide-pt-lg { display: none !important; }
  }

  /** XL
  *********************************************/
  @media (min-width: 1920px) {
    .hide-pt-xl { display: none !important; }
  }

 }









 /** LANDSCAPE
*********************************************/
@media screen and (orientation:landscape) {

  /** XS
  *********************************************/
  @media (max-width: 599px) {
    .hide-ls-xs { display: none !important; }
  }

  /** SM
  *********************************************/
  @media (min-width: 600px) and (max-width: 959px) {
    .hide-ls-sm { display: none !important; }
  }

  /** MD
  *********************************************/
  @media (min-width: 960px) and (max-width: 1279px) {
    .hide-ls-md { display: none !important; }
  }

  /** LG
  *********************************************/
  @media (min-width: 1280px) and (max-width: 1919px) {
    .hide-ls-lg { display: none !important; }
  }

  /** XL
  *********************************************/
  @media (min-width: 1920px) {
    .hide-ls-xl { display: none !important; }
  }

 }