.page {
  position: relative;
  display: flex;
  flex-direction: row;
  height: 100%;
  padding: 56px;
}

h1.title {
  margin: 0;
  font-family: Roboto-Thin;
  font-weight: 100;
  font-size: 48px;
  color: white;
}

.simple-trans-main > div {
  cursor: pointer;
  position: absolute;
  width: 100%;
  height: 100%;
  will-change: transform, opacity;
  text-shadow: 0px 2px 40px #00000020, 0px 2px 5px #00000030;
}

.project-name {
  margin: 0;
  font-family: Roboto-Light;
  font-weight: 100;
  font-size: 48px;
  color: white;
}

.project-bullet {
  font-family: Roboto-Light;
  font-size: 16px;
  color: white;
  will-change: transform, opacity;
  overflow: hidden;
  position: relative;
  margin-bottom: 24px;
}

.trails-main {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
  cursor: pointer;
  display: flex;
}

.trails-text {
  position: relative;
  width: 100%;
  font-family: Roboto-Light;
  font-size: 16px;
  color: white;
  will-change: transform, opacity;
  overflow: hidden;
}

.trails-text > div {
  overflow: hidden;
}

a.button, div.button {
  background: transparent;
  border: 1px solid white;
  border-radius: 4px;
  padding: 8px;
  font-family: Roboto-Light;
  font-size: 12px;
  color: white;
  cursor: pointer;
  user-select: none;
  text-decoration: none;
}

a.button:hover, div.button:hover {
  background: white;
  color: #332549;
}

a.button:active, div.button:active {
  background: #eaeaea;
  color: #332549;
}

.project-page {
  max-width: 65%;
  max-height: 65%;
  margin-right: 64px
}

.project-buttons {
  position: absolute;
  right: 0;
  width: 80px !important;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  -webkit-tap-highlight-color: transparent;
}

.logo-button {
  width: 56px;
  height: 56px;
}