/** PORTRAIT
*********************************************/
@media screen and (orientation:portrait) {

  /** XS
  *********************************************/
  @media (max-width: 599px) {
    .contact { flex-direction: column; }
    .contact .left { flex: 0; align-items: center; margin-bottom: 16px; padding-left: 0; }
    .contact .page-title { font-size: 24px; margin-top: 24px; margin-bottom: 24px; }
    .contact .whatsapp-xs { flex: 1; width: 80%; margin-top: 15%; margin-left: 50%; transform: translate(-50%); }
  }

  /** SM
  *********************************************/
  @media (min-width: 600px) and (max-width: 959px) {
    .contact .whatsapp-xs { display: none; }

    .contact .left { flex: 1; padding-left: 0; padding: 16px; }
    .contact .right { flex: 1; padding-left: 0; padding: 16px; justify-content: center; }
    .contact .page-title { font-size: 40px; margin-bottom: 24px; }
    .contact .title { font-size: 32px !important; }
    .contact .phone-placeholder { height: 60% !important; }
  }

  /** MD
  *********************************************/
  @media (min-width: 960px) and (max-width: 1279px) {
    .contact .whatsapp-xs { display: none; }

    .contact .left { flex: 1; padding-left: 0; padding: 16px; }
    .contact .right { flex: 1; padding-left: 0; padding: 16px; justify-content: center; }
    .contact .page-title { margin-bottom: 24px; }
    .contact .title { font-size: 32px !important; }
    .contact .phone-placeholder { height: 65% !important; }
  }

  /** LG
  *********************************************/
  @media (min-width: 1280px) and (max-width: 1919px) {
    .contact .whatsapp-xs { display: none; }

    .contact .left { flex: 1; padding-left: 0; padding: 16px; }
    .contact .right { flex: 1; padding-left: 0; padding: 16px; justify-content: center; }
    .contact .phone-placeholder { height: 65% !important; }
  }

  /** XL
  *********************************************/
  @media (min-width: 1920px) {
    .contact .whatsapp-xs { display: none; }

    .contact .left { flex: 1; padding-left: 0; padding: 16px; }
    .contact .right { flex: 1; padding-left: 0; padding: 16px; justify-content: center; }
    .contact .phone-placeholder { height: 65% !important; }
  }

 }




 /** LANDSCAPE
*********************************************/
@media screen and (orientation:landscape) {

  /** XS
  *********************************************/
  @media (max-width: 599px) {
    .contact .page-title { font-size: 24px; margin-bottom: 24px; }
    .contact .whatsapp-xs { flex: 1; }
  }

  /** SM
  *********************************************/
  @media (min-width: 600px) and (max-width: 959px) {
    .contact .page-title { font-size: 24px; margin-bottom: 24px; }
    .contact .whatsapp-xs { flex: 1; }
  }

  /** MD
  *********************************************/
  @media (min-width: 960px) and (max-width: 1279px) {
    .contact .whatsapp-xs { display: none; }
  }

  /** LG
  *********************************************/
  @media (min-width: 1280px) and (max-width: 1919px) {
    .contact .whatsapp-xs { display: none; }
  }

  /** XL
  *********************************************/
  @media (min-width: 1920px) {
    .contact .whatsapp-xs { display: none; }
  }

 }