@font-face {
  font-family: 'Roboto-Thin';
  src: url('./assets/fonts/Roboto-Thin.ttf');
}

@font-face {
  font-family: 'Roboto-Light';
  src: url('./assets/fonts/Roboto-Light.ttf');
}

@font-face {
  font-family: 'Roboto-Medium';
  src: url('./assets/fonts/Roboto-Medium.ttf');
}

@font-face {
  font-family: 'Roboto';
  src: url('./assets/fonts/Roboto-Regular.ttf');
}

@font-face {
  font-family: 'Pixel';
  src: url('./assets/fonts/PixelDigivolve-mOm9.ttf');
}

html, body {
  font-family: 'Roboto', sans-serif;
  height: 100%;
  margin: 0;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.relative { position: relative; }
.absolute { position: absolute; }
.full-vh { height: 100vh; }
.full-vw { width: 100vw; }
.full-width { width: 100%; }
.full-height { height: 100%; }
.bottom-0 { bottom: 0; }

.padding-8 { padding: 8px }
.padding-16 { padding: 16px }
.padding-24 { padding: 24px }
.padding-32 { padding: 32px }

.padding-v-8 { padding-top: 8px; padding-bottom: 8px; }
.padding-v-16 { padding-top: 16px; padding-bottom: 16px }
.padding-v-24 { padding-top: 24px; padding-bottom: 24px }
.padding-v-32 { padding-top: 32px; padding-bottom: 32px }

.margin-top-4 { margin-top: 4px; }
.margin-top-8 { margin-top: 8px; }
.margin-top-12 { margin-top: 12px; }
.margin-top-16 { margin-top: 16px; }
.margin-top-24 { margin-top: 24px; }

.margin-left-4 { margin-left: 4px; }
.margin-left-8 { margin-left: 8px; }
.margin-left-12 { margin-left: 12px; }
.margin-left-16 { margin-left: 16px; }
.margin-left-24 { margin-left: 24px; }

.margin-right-4 { margin-right: 4px; }
.margin-right-8 { margin-right: 8px; }
.margin-right-12 { margin-right: 12px; }
.margin-right-16 { margin-right: 16px; }
.margin-right-24 { margin-left: 24px; }

.flex { display: flex; }
.flex-1 { flex: 1; }
.flex-2 { flex: 2; }
.flex-3 { flex: 3; }
.flex-4 { flex: 4; }
.flex-5 { flex: 5; }
.column { flex-direction: column; }
.row { flex-direction: row; }
.justify-center { justify-content: center; }
.justify-start { justify-content: flex-start; }
.justify-end { justify-content: flex-end; }
.align-center { align-items:  center; }
.space-evenly { justify-content: space-evenly }
.space-around { justify-content: space-around }
.space-between { justify-content: space-between }

.pointer { cursor: pointer; }
.move { cursor: move; }

.opacity-0 { opacity: 0; }
.opacity-1 { opacity: 2; }

.page-title {
  font-family: Roboto-Thin;
  font-weight: 100;
  font-size: 48px;
  color: white;
}