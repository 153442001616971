.design-thinking {
  display: flex;
  flex-direction: row;
}

.design-thinking .page-title {
  margin-bottom: 64px;
}

.design-thinking .description {
  padding: 24px;
}

.design-thinking .bullet {
  margin-bottom: 32px;
}

.design-thinking .bullet span {
  font-family: Roboto-Thin;
  font-size: 20px;
  color: white;
}

.design-thinking .meet {
  position: fixed;
  bottom: 0;
  margin-left: 5%;
  width: 40%;
}

.design-thinking .rocket-bulb {
  margin-left: 17%;
  width: 15%
}