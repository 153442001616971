/** PORTRAIT
*********************************************/
@media screen and (orientation:portrait) {

  /** XS
  *********************************************/
  @media (max-width: 599px) {
    .page {
      flex-direction: column !important;
      padding: 12px !important;
    }

    .description-container .wrapper {
      margin-top: 16px !important;
    }

    .title {
      font-size: 18px !important;
    }

    .project-name {
      font-size: 18px !important;
    }

    .project-bullet {
      margin-bottom: 12px !important;
      font-size: 12px !important;
    }

    .project-bullet div.button {
      font-size: 10px !important;
    }

    .project-page {
      max-width: 80% !important;
      max-height: 80% !important;
    }

    .project-buttons {
      width: 40px !important;
    }

    .logo-button {
      width: 30px !important;
      height: 30px !important;
    }
  }

  /** SM
  *********************************************/
  @media (min-width: 600px) and (max-width: 959px) {
    .page {
      flex-direction: column !important;
      padding: 32px
    }

    .description-container .wrapper {
      margin-top: 24px !important;
    }

    .title {
      font-size: 48px !important;
    }
    
    .project-name {
      font-size: 48px !important;
    }

    .project-bullet {
      margin-bottom: 20px !important;
      font-size: 20px !important;
    }

    .project-bullet div.button {
      font-size: 20px !important;
    }

    .project-page {
      max-width: 100% !important;
      max-height: 100% !important;
    }

  }

  /** MD
  *********************************************/
  @media (min-width: 960px) and (max-width: 1279px) {
    .page {
      flex-direction: column !important;
      padding: 24px
    }

    .description-container .wrapper {
      margin-top: 24px !important;
    }

    .title {
      font-size: 56px !important;
    }
    
    .project-name {
      font-size: 56px !important;
    }

    .project-bullet {
      margin-bottom: 24px !important;
      font-size: 24px !important;
    }

    .project-bullet div.button {
      font-size: 24px !important;
    }

    .project-buttons {
      width: 64px !important;
    }

    .logo-button {
      width: 50px !important;
      height: 50px !important;
    }

    .project-page {
      max-width: 90% !important;
      max-height: 90% !important;
    }

  }

  /** LG
  *********************************************/
  @media (min-width: 1280px) and (max-width: 1919px) {
  }

  /** XL
  *********************************************/
  @media (min-width: 1920px) {
  }

}










/** LANDSCAPE
*********************************************/
@media screen and (orientation:landscape) {

  /** XS
  *********************************************/
  @media (max-width: 599px) {
    .page {
      padding: 16px !important;
    }

    .description-container .wrapper {
      margin-top: 24px !important;
    }

    .title {
      font-size: 16px !important;
    }

    .project-name {
      font-size: 16px !important;
    }

    .project-bullet {
      margin-bottom: 12px !important;
      font-size: 10px !important;
    }

    .project-bullet div.button {
      font-size: 10px !important;
    }

    .project-page {
      max-width: 50% !important;
      max-height: 50% !important;
    }

    .project-buttons {
      width: 30px !important;
    }

    .logo-button {
      width: 20px !important;
      height: 20px !important;
    }
  }

  /** SM
  *********************************************/
  @media (min-width: 600px) and (max-width: 959px) {
    .page {
      padding: 16px !important;
    }

    .description-container .wrapper {
      margin-top: 24px !important;
    }

    .title {
      font-size: 20px !important;
    }

    .project-name {
      font-size: 20px !important;
    }

    .project-bullet {
      margin-bottom: 12px !important;
      font-size: 12px !important;
    }

    .project-bullet div.button {
      font-size: 12px !important;
    }

    .project-page {
      max-width: 50% !important;
      max-height: 50% !important;
    }

    .project-buttons {
      width: 40px !important;
    }

    .logo-button {
      width: 25px !important;
      height: 25px !important;
    }
  }

  /** MD
  *********************************************/
  @media (min-width: 960px) and (max-width: 1279px) {

    .page {
      padding: 16px !important;
    }

    .description-container .wrapper {
      margin-top: 24px !important;
    }

    .title {
      font-size: 40px !important;
    }

    .project-name {
      font-size: 40px !important;
    }

    .project-bullet {
      margin-bottom: 18px !important;
      font-size: 18px !important;
    }

    .project-bullet div.button {
      font-size: 18px !important;
    }

    .project-page {
      max-width: 50% !important;
      max-height: 50% !important;
    }

    .project-buttons {
      width: 48px !important;
    }

    .logo-button {
      width: 40px !important;
      height: 40px !important;
    }

  }

  /** LG
  *********************************************/
  @media (min-width: 1280px) and (max-width: 1919px) {
  }

  /** XL
  *********************************************/
  @media (min-width: 1920px) {
  }

}