/** PORTRAIT
*********************************************/
@media screen and (orientation:portrait) {

  .design-thinking { flex-direction: column; }
  .design-thinking .meet { width: 60%; margin-left: 50%; transform: translate(-50%); }
  .design-thinking .rocket-bulb { width: 22%; margin-left: 50%; transform: translate(-55%); }

  /** XS
  *********************************************/
  @media (max-width: 599px) {
    .design-thinking .page-title { font-size: 24px; margin-bottom: 32px; }
    .design-thinking .description { padding: 16px; }
    .design-thinking .bullet { margin-bottom: 24px; }
    .design-thinking .bullet span { font-size: 16px; }
  }

  /** SM
  *********************************************/
  @media (min-width: 600px) and (max-width: 959px) {
    .design-thinking .page-title { font-size: 48px; margin-bottom: 48px; }
    .design-thinking .description { padding: 24px; }
    .design-thinking .bullet { margin-bottom: 48px; }
    .design-thinking .bullet span { font-size: 24px; }
  }
  
  /** GT-MD
  *********************************************/
  @media (min-width: 960px) {
    .design-thinking .page-title { font-size: 56px; margin-bottom: 56px; }
    .design-thinking .description { padding: 32px; }
    .design-thinking .bullet { margin-bottom: 48px; }
    .design-thinking .bullet span { font-size: 32px; }
  }

 }




 
/** LANDSCAPE
*********************************************/
@media screen and (orientation:landscape) {

  /** XS
  *********************************************/
  @media (max-width: 599px) {
    .design-thinking .page-title { font-size: 20px; margin-bottom: 24px; }
    .design-thinking .description { padding: 8px; }
    .design-thinking .bullet { margin-bottom: 16px; }
    .design-thinking .bullet span { font-size: 12px; }
  }

  /** SM
  *********************************************/
  @media (min-width: 600px) and (max-width: 959px) {
    .design-thinking .page-title { font-size: 32px; margin-bottom: 24px; }
    .design-thinking .description { padding: 8px; }
    .design-thinking .bullet { margin-bottom: 16px; }
    .design-thinking .bullet span { font-size: 14px; }
  }

 }